import { showUser } from "../features/userDetailSlice";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";


const Read = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedGenre, setSelectedGenre] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const { users, loading, error } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(showUser());
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Filter Movies based on the selected genre
  const genreFilteredMovies = selectedGenre === "All"
    ? users
    : users.filter(user => user.Genre && user.Genre.includes(selectedGenre));

  // Filter Movies based on the search query
  const searchFilteredMovies = users.filter(user => 
    typeof user.Title === 'string' && user.Title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Combine both filters (intersect results)
  const filteredMovies = genreFilteredMovies.filter(movie =>
    searchFilteredMovies.includes(movie)
  );

  // Pagination
  const usersPerPage = 6;
  const offset = currentPage * usersPerPage;
  const currentUsers = filteredMovies.slice(offset, offset + usersPerPage);
  const pageCount = Math.ceil(filteredMovies.length / usersPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleGenreChange = (event) => {
    setSelectedGenre(event.target.value);
    setCurrentPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(0);
  };

  return (
    <div className="Main container">
      {/* <h1 className="m-5">Movie Streaming</h1> */}
      <div className="d-flex justify-content-between row">
       
        <div className="col-md-8">
            {/* Search input field */}
      <div className="mb-3">
        <label htmlFor="searchInput" className="form-label">Search Movie:</label>
        <input
          type="text"
          id="searchInput"
          className="form-control"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>

        </div>
        <div className="col-md-4">
          
          {/* Genre selection dropdown */}
      <div className="mb-3">
        <label htmlFor="genreSelect" className="form-label">Genre:</label>
        <select
          id="genreSelect"
          className="form-select"
          value={selectedGenre}
          onChange={handleGenreChange}
        >
          <option value="All">All Genres</option>
          <option value="Action">Action</option>
          <option value="Thriller">Thriller</option>
          <option value="Comedy">Comedy</option>
          <option value="Crime">Crime</option>
          <option value="Family">Family</option>
          <option value="Romance">Romance</option>
          <option value="Adventure">Adventure</option>
          <option value="Horror">Horror</option>
          <option value="Fantasy">Fantasy</option>
          <option value="Sci-Fi">Sci-Fi</option>
        </select>
      </div>
      </div>
      </div>
      

    
      <p className="mb-3">
        Showing {filteredMovies.length} {filteredMovies.length === 1 ? 'result' : 'results'} for the selected genre and search movie.
      </p>

      <div className="row">
        {currentUsers.map((user) => (
          <div className="col-md-4" key={user.imdbId}>
            <div className="row mb-5">
              <img className="grow-image p-3" src={user.Poster} alt={user.Title} />
              <div>
                <h2>{user.Title}</h2>
                <p>IMDB Score: {user["IMDB Score"]}</p>
                <p>Genre: {user.Genre}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={0}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active btn btn-primary"}
      />
    </div>
  );
};

export default Read;
