import "bootstrap/dist/css/bootstrap.min.css" 
import './App.css';
import Navbar from './Component/Navbar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Read from "./Component/Read";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Read/>}/>
        {/* <Route exact path="/create" element={<Create/>}/>
        <Route exact path="/edit/:id" element={<Update />}/> */}
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
