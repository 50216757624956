import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// read || Show Movie
export const showUser = createAsyncThunk(
  "showUser",
  async (args, { rejectWithValue }) => {
    try {
      const response = await fetch('/movies.json');
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// extraReducer Builder functions for actions

// showMovie
const showUserPending = (state) => {
  state.loading = true;
};

const showUserFulfilled = (state, action) => {
  state.loading = false;
  state.users = action.payload;
};

const showUserRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};


export const userDetail = createSlice({
  name: "userDetail",
  initialState: {
    users: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(showUser.pending, showUserPending)
      .addCase(showUser.fulfilled, showUserFulfilled)
      .addCase(showUser.rejected, showUserRejected);
  },
});

export default userDetail.reducer;