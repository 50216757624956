import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Navbar = () => {
  const allData = useSelector((state) => state.app.users);

  return (
    <div className="container">
    <nav className="navbar navbar-expand-lg bg-body-tertiary bg-transparent pt-3 pb-5">
     
        <Link className="navbar-brand text-white fs-2" to="/">
          Movie Tracking System
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon bg-red"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style={{ "--bs-scroll-height": "100px" }}>
            <li className="nav-item">
              <Link className="nav-link active" to="/">
                All Movies <span className="blink">{allData.length}</span>
              </Link>
            </li>
          </ul>
          
      </div>
    </nav>
    </div>
  );
};

export default Navbar;
